import React from "react";
import Header from "../../components/header"
import Footer from "../../components/footer"
import { Helmet } from "react-helmet"
import InfoCard from "../../components/infocard"
import jarvis_head from "../../images/jarvis_head.png"
import jarvis from "../../images/jarvis.png"
import { Link } from "gatsby"

const Page = class extends React.Component {
    render() {
        return(
            <>
                <Helmet>
                    <title>ChatForge | Jarvis</title>
                    <meta name="description" content="Jarvis can help customers engage with your business. He can automate processes such as scheduling and payments whilst also keeping you in the loop using Conductor."/>
                </Helmet>
                <body class="mainPage">
                    <Header />
                    <div class="content">
                        <div class="section">
                            <div class="bot-profile-head">
                                <img src={jarvis} alt="Jarvis" class="show-desktop"/>
                                <div class="profile-info">
                                    <h1 class="bot-name">
                                        Jarvis
                                    </h1>
                                    <img src={jarvis_head} alt="Jarvis" class="show-mobile"/>
                                    <h2 class="bot-name">
                                    Health & Safety
                                    </h2>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. 
                                    </p>
                                </div>
                            </div>
                            
                            <div class="bot-card-container">
                                <InfoCard botJob="Thing this bot can do" botDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."/>
                                <InfoCard botJob="Thing this bot can do" botDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."/>
                                <InfoCard botJob="Thing this bot can do" botDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."/>

                            </div>
                            <div class=" bot-card">
                                    <h3 class="bot-card-text">
                                        FAQ Service
                                    </h3>
                                <p class="ourteambotinfo">
                                    If you have any questions, please refer them to our customer service FAQ bot.
                                </p>
                                <div id="tryit-text">
                                    <Link to={`/ourbots/OurTeamBot`}>
                                        <b>Read More About Our FAQ bot</b>
                                    </Link>
                                </div>       
                             </div>
                        </div>
                    </div>
                    <Footer />
                </body>
            </>
        )   
    }    
}

export default Page
